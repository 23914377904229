import React, { useState, useEffect } from "react";
import ReactHlsPlayer from "react-hls-player";
import { Link, useHistory, useLocation } from "react-router-dom";
import { service } from "../../network/Video/service";
import { useSelector, useDispatch } from "react-redux";
import EpisodeDetails from "./EpisodeDetails";
import { convertAdUrl } from "../../Utils/utils";
import "./Video.css";

var details = [];
var subtitle = [];
var videoDetailUtils = [];
const VideoPlayer = (history) => {
  let subTitle = [];
  const [videoPlayer, setVideoPlayer] = useState(
    <video
      id="content_video"
      className="video-js vjs-default-skin mainPlayer"
      controls="true"
      preload="auto"
    >
      {" "}
      <source src="" type="video/mp4" />{" "}
    </video>
  );
  const [videoDetails, setVideoDetails] = useState();
  const historys = useHistory();
  // const [htmlId] = useId();
  const login = useSelector((state) => state.login);
  const data = { show: history.location.state };
  useEffect(() => {
    window.scrollTo(0, 0);
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    let userId = service.getCookie("userId");
    console.log("userId", userId);
    if (isLoggedIn === "true" && userId) {
      let show_details = "";
      let showId = "";
      if (history.location.state.showId) {
        showId = history.location.state.showId;
      } else {
        showId = history.location.state.show_details.show_id;
      }
      localStorage.setItem("showId", showId);
      service.getShowDetails(showId).then((response) => {
        //subTitle=history.location.state.show_details.show_id;
        subTitle = history.location.state.show_details.subtitles;
        console.log("subTitle", subTitle);
        let dataDetails = response.data.videos;
        let showDetails = response.data;
        dataDetails.map((item, index) => {
          if (item.video_id === history.location.state.show_details.video_id) {
            show_details = item;
          }
        });

        setVideoDetails(show_details);
        details = show_details;
        videoDetailUtils = showDetails;
        var subtitles = [];
        subtitles = dataDetails.subtitles;
        service.playerToken().then((tokenResponse) => {

          console.log('show_detailsqq', show_details)
          let arr = show_details.video_name.split("/").reverse();
          // console.log('arrId', arr[arr.length-2] )
          let newURL =
            "https://poppo.tv/playlist/playlist.m3u8?id=" +
            arr[1] +
            "&token=" +
            tokenResponse.data.data +
            "&type=video";

          if(history.location.state.isLive && history.location.state.isLive === true) {
            newURL = show_details.video_name;
          }
          let videoElem =
            "content_video" + show_details.video_id + new Date().valueOf();
          setVideoPlayer(
            <video
              id={videoElem}
              className="video-js vjs-default-skin mainPlayer"
              controls="true"
              preload="auto"
              autoPlay
              x-webkit-airplay="deny"
              crossorigin="anonymous"
            >
              <source src={newURL} type="application/x-mpegURL" />

              {subTitle &&
                subTitle.map((item) => {
                  return (
                    <track
                      label={item.language_name}
                      kind="subtitles"
                      srclang={item.short_code}
                      src={item.subtitle_url}
                    />
                  );
                })}
            </video>
          );
          setTimeout(() => {
            var myClasses =
              document.getElementsByClassName("vjs-menu-item-text");
            if (myClasses) {
              myClasses[1].innerHTML = "Off";
            }
          }, 3000);
          // show_details.ad_link = 'http://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/124319096/external/ad_rule_samples&ciu_szs=300x250&ad_rule=1&impl=s&gdfp_req=1&env=vp&output=xml_vmap1&unviewed_position_start=1&cust_params=sample_ar%3Dpremidpostpod%26deployment%3Dgmf-js&cmsid=496&vid=short_onecue&correlator=';
          let adUrl = convertAdUrl(show_details, videoDetailUtils);
          if(history.location.state.isLive && history.location.state.isLive === true) {
            adUrl = '';
          }
          window.playMainPlayer(
            adUrl,
            videoElem,
            show_details.video_id,
            details,
            showDetails
          );
        });
      });
    } else {
      historys.push({
        pathname: "/signin",
      });
    }
  }, [login]);

  const AddTripButton = (item) => {
    return (
      <track
        label="English"
        kind="subtitles"
        srclang="en"
        src={item.url}
        default
      />
    );
  };

  window.onVideoPlay = (videoId, vd, show_details) => {
    let event = "POP02";
    service.checkVideoSubscription(videoId).then((response) => {
      let videoDetails = response.data;
      if (
        videoDetails.premium_flag == 1 ||
        videoDetails.payper_flag == 1 ||
        videoDetails.rental_flag == 1
      ) {
        service.checkUserSubscription().then((useResponse) => {
          if (useResponse.data.length == 0) {
            let isLoggedIn = localStorage.getItem("isLoggedIn");
            if (isLoggedIn == "false") {
              history.push({
                pathname: "/signin",
              });
            }
          }
        });
      } else {
        // console.log('playing...')
      }
    });
    service.onVideoPlayFunction(vd, event, show_details).then((response) => {});
  };
  window.onVideoPlayInteval = (vd, show_details) => {
    let event = "POP03";
    service.onVideoPlayFunction(vd, event, show_details).then((response) => {});
  };
  window.onVideoResume = (vd, show_details) => {
    let event = "POP09";
    service.onVideoPlayFunction(vd, event, show_details).then((response) => {});
  };
  window.onVideoPause = (vd, show_details) => {
    localStorage.setItem("play", false);
    let event = "POP04";
    service.onVideoPlayFunction(vd, event, show_details).then((response) => {});
  };
  window.onVideoEnd = (vd, show_details) => {
    let event = "POP05";
    service.onVideoPlayFunction(vd, event, show_details).then((response) => {
      historys.push({
        pathname: "/home",
      });
    });
  };

  return (
    <div className="pageWrapper searchPageMain">
      <div className="topContainer">
        <div className="homepageWrapper menuCloseJS closeMenuWrapper">
          <div className="entireBanner" style={{ zIndex: "2" }} id="live">
            <div className="hpLiveBanner">
              <div className="liveVideoWrapper">{videoPlayer}</div>
            </div>
          </div>
          {history.location.state.singleVideo == 0 ? (
            <EpisodeDetails categoryId={data} />
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default VideoPlayer;
