import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-modal';
import { service } from "../../network/service";
import {textTruncate} from '../../Utils/utils'
import CategoryContainer from "../Home/CategoryContainer";
import './style.css'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const Podcast = () => {
    const [podcastListIems, setPodcastListIems] = useState(null)
    const [modalIsOpen, setIsOpen] = useState(false);
    const [activeTrack, setActiveTrack] = useState(true);
    const [podscastCategories,setPodcastCategories] = useState([])

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchPodcastTracks();
    }, []);

    const fetchPodcastTracks = async () => {
        const podcastList = await service.getPodcastTracks()
        if (podcastList?.status == 200) {
            // setPodcastListIems(podcastList.data?.data?.map((item, index) => {
            //     const { name, url, image, description } = item
            //     return <div className="podcast-item" onClick={() => {
            //         setIsOpen(true)
            //         setActiveTrack(url)
            //     }}>
            //         <div><img src={image} /></div>
            //         <div><h6 className="title" title={name}>{textTruncate(name, 25, '...')}</h6></div>
            //         <div className="description">{description}</div>
            //     </div>

            // }))
            setPodcastCategories(podcastList?.data?.data)
        }
    };

    return (
        <>
            <Modal
                isOpen={modalIsOpen}
                style={customStyles}
            >
                <div>

                    <div className="modal-close-wrapper" onClick={() => {
                        setIsOpen(false)
                        setActiveTrack(null)
                    }}><span className="modal-close">close</span></div>

                    <audio controls>
                        {activeTrack && <source src={activeTrack} type="audio/mpeg" />}
                    </audio>
                </div>

            </Modal>
            <div className="pageWrapper searchPageMain" style={{paddingTop:"130px"}}>
                <div className="topContainer">
                    <div className="menuCloseJS closeMenuWrapper">
                        <div className="activateContainer">
                        {
                podscastCategories?.map((item,index)=>(

                    <CategoryContainer 
                    key={index}
                     param={item}
                     podcast={true}
                     setIsOpen={setIsOpen}
                     setActiveTrack={setActiveTrack}
                     />
                ))
            }
                            {/* <div className="activate-style">
                           
                                <div>
                                    <h6>Podcast</h6>
                                </div>
                                <div className="podcast-wrapper">
                                    
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    );
};
export default Podcast;
