import React, { useState, useEffect } from 'react';
import $ from 'jquery';
const AboutUs = () => {
	window.scrollTo(0, 0);
	useEffect(() => {
		$('.menuItemContainer').addClass('menuClose');
	}, []);
	return (
		<div>
			<div className="pageWrapper">
				<div className="topContainer">
					<div className="menuCloseJS closeMenuWrapper">
						<div id="content" className="site-content">
							<div id="primary" className="content-area">
								<main id="main" className="site-main">
									<article id="post-39" className="post-39 page type-page status-publish hentry">
										<div className="entry-content">
											<div className="vc_row wpb_row vc_row-fluid vc_custom_1584031025503 bb_custom_1584031025503">
												<div className="wpb_column vc_column_container vc_col-sm-12">
													<div className="vc_column-inner">
														<div className="wpb_wrapper">
															<div className="discover-header" style={{ height: '900px' }}>
																<div id="main-bg" className="main-bg" style={{ top: '0px' }}></div>
																<div className="header-content">
																	<h1>About Discover Wisconsin
																		<b></b><span>
																			As the nation’s longest-running travel & tourism TV series, award-winning Discover Wisconsin highlights destinations, adventures, and local initiatives that bring our communities together across America's Dairyland.</span>
																		<b></b><span>Discover Wisconsin can be streamed worldwide on Roku, AppleTV, SmartTV, Google Chromecast, Amazon Fire, YouTube and discoverwisconsin.com. The show can be seen regionally on Bally Sports Wisconsin Saturday mornings at 10 a.m.</span>
																		<b></b><span>For more on this and other episodes or the broadcast schedule in other areas please visit <a href='https://discoverwisconsin.com/'>www.discoverwisconsin.com</a>. Connect with Discover Wisconsin on <a href='https://www.facebook.com/discoverwisconsin'>Facebook</a>, <a href='http://instagram.com/discoverwisconsin'>Instagram</a>, <a href='https://twitter.com/DiscoverWI'>Twitter</a> and <a href='https://www.youtube.com/channel/UCBSpJgEHpQo7VnLUFp8ry_w'>YouTube</a> and subscribe to The Cabin Podcast on Apple Podcasts, Spotify or wherever you listen to your favorite podcasts. </span>


																	</h1></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</article>
								</main>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default AboutUs;
